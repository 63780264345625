<template>
  <!-- 签到 -->
  <div class="Signin">
    <div class="wrap">
      <div class="head">
        <div class="detail">
          <div>
            <span><span>{{lian_num}}</span>天</span>
            <span>连续签到</span>
          </div>
          <div>
            <span><span>{{lou_num}}</span>天</span>
            <span>已漏签</span>
          </div>
        </div>
      </div>
      <!-- 签到日期 -->
      <div class="time">
        <div class="box">
          <div class="title"><span @click="left">&lt; </span>{{year}}年{{month}}月<span @click="right">	&gt;</span></div>
          <div class="list">
            <span style="opacity: 0" class="day"></span>
            <span style="opacity: 0" class="day"></span>
            <span v-for="(item,index) in time_list" :key="index" class="day" :class="{'success': item.clock == 1,'fail': item.clock == 0}" @click="toStudy(item.day)">{{item.day}}<span v-if="item.clock == 0">漏签</span></span>
          </div>
        </div>
      </div>
      <div class="receive" @click="lingqu" v-if="is_success == 1"><span>领取奖励</span></div>
      <div class="receive" v-if="is_success == 2"><span>已申请待发放</span></div>
      <div class="receive" v-if="is_success == 3"><span>已发放</span></div>
    </div>
    <!-- 提示 -->
    <fadeAlert :msg="msg" v-if="showAlert" @hideFade="showAlert=false"></fadeAlert>
    <loading :ifload="ifload"></loading>
  </div>
</template>

<script>
import fadeAlert from '@/components/fadeAlert'
import loading from '@/components/loading'
export default {
  components: {
    fadeAlert,
    loading,
  },
  data() {
    return {
      headerName: '每日签到',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      buqian_show: false, // 补签
      buqian1_show: false, // 补签成功
      time: '', // 当前时间
      time_list: [], // 日历
      lian_num: '', // 连续签到天数
      lou_num: '', // 漏签天数
      year: '', // 年份
      month: '', // 月份
      day: '', // 日
      day1: '', // 接口日
      type: 1,
      number: '', // 免费补签次数
      is_success: '',
      startY: '', // 开始年
      startM: '', // 开始月
      startD: '', // 开始日
      endY: '', // 结束年
      endM: '', // 结束月
    }
  },
  mounted () {
    // 获取当前时间
    var date = new Date()
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var day = date.getDate();
    this.time = year+'-'+month+'-'+day;
    this.year = year;
    this.month = month;
    this.endY = year;
    this.endM = month
    this.day = day;
    this.getday();
  },
  methods: {
    // 获取日期列表
    getday () {
      this.$post('/ClockInc/getClockinData',{
        date: this.year+'-'+this.month
      },(res)=>{
        this.startY = res.data.data.start_ym.split('-')[0]
        this.startM = res.data.data.start_ym.split('-')[1]
        this.startD = res.data.data.start_ym.split('-')[2]
        if (res.data.code == 1) {
          var lou_num = 0
          this.time_list = res.data.data.day_list;
          this.is_success = res.data.data.is_success;
          for (let i = 0; i < this.time_list.length; i++) {
            if (this.time_list[i].clock == 0) {
              lou_num ++;
            }
            if (this.time_list[i].day == this.day && this.time_list[i].clock == 1) {
              this.type = 2;
            }
          }
          this.lian_num = res.data.data.con_sign;
          this.lou_num = lou_num;
          this.number = res.data.data.bqcount;
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 点击每一天去每日一句
    toStudy (day) {
      if (day < 10) {
        day = '0' + day;
      }
      var date = new Date().getTime(); // 当前时间
      var time = this.year + '-' + this.month + '-' + day; // 当前点击时间
      var time2 = this.startY + '-' + this.startM + '-' + this.startD; // 开始时间
      var timetamp = Number(new Date(time).getTime()); // 当前点击时间
      var timetamp2 = Number(new Date(time2).getTime()); // 开始时间
      if (timetamp >= timetamp2 && timetamp <= date) {
        this.$router.replace({name: 'study',query:{time}})
      }
    },
    // 领取
    lingqu () {
      this.$post('/clockinc/applyReward',{},(res)=>{
        if (res.data.code == 1) {
          this.getday();
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 左翻
    left () {
      if (this.year <= this.startY && this.month <= this.startM) {
        return
      }
      this.month--;
      if (this.month < 10) {
        this.month = '0'+this.month;
      }
      if (this.month < 1) {
        this.year--;
        this.month = 12;
      }
      this.getday();
    },
    // 右翻
    right () {
      if (this.year >= this.endY && this.month >= this.endM) {
        return
      }
      this.month++;
      if (this.month < 10) {
        this.month = '0'+this.month;
      }
      if (this.month > 12) {
        this.year++;
        this.month = 1;
        if (this.month < 10) {
          this.month = '0'+this.month;
        }
      }
      this.getday();
    }
  }
}
</script>

<style lang='scss' scoped>
.Signin {
  .wrap {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 92%;
    border-top: 3px solid white;
    background: #F2ECD6;
    .btn {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      span {
        display: inline-block;
        width: 130px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        color: white;
        background: #EAA24D;
        border-radius: 20px;
      }
    }
    .head {
      padding: 0 50px;
      padding-bottom: 20px;
      .detail {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 80px;
          }
          span {
            text-align: center;
            font-size: 18px;
            color: #5B5B5B;
            span {
              font-size: 30px;
              color: #EAA24D;
            }
          }
          .p1 {
            font-size: 16px;
          }
        }
      }
      .start {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        span {
          display: inline-block;
          width: 130px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          color: white;
          background: #EAA24D;
          border-radius: 20px;
        }
      }
    }
    .time {
      padding: 0 10px;
      .box {
        width: 100%;
        border: 2px solid #E6A045;
        border-radius: 10px;
        height: 300px;
        box-shadow: inset 2px 2px 50px 5px #E6A045;
        .title {
          border-bottom: 2px solid #E6A045;
          color: #EAA24D;
          font-size: 25px;
          text-align: center;
          padding: 5px 0;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          padding: 5px;
          .day {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            border: 2px solid #EAA24D;
            color: #EAA24D;
            margin-left: 5px;
            margin-bottom: 5px;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
            border-radius: 10px;
            span {
              font-size: 12px;
              color: #FF0000;
              background: white;
              position: absolute;
              bottom: -2px;
              left: -2px;
              width: 110%;
              height: 15px;
              line-height: 15px;
              text-align: center;
              border-radius: 0 0 10px 10px;
            }
          }
        }
      }
    }
    .receive {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      span {
        display: inline-block;
        width: 130px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        color: white;
        background: #EAA24D;
        border-radius: 20px;
      }
    }
    // 签到规则
    .rule {
      padding: 10px;
      .title {
        text-align: center;
        font-size: 18px;
        color: #5B5B5B;
      }
      span {
        display: inline-block;
        margin-top: 15px;
        font-size: 15px;
        color: #5B5B5B;
      }
    }
  }
  // 补签弹窗
  .buqian {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    z-index: 2;
    .box {
      position: fixed;
      left: 10%;
      top: 30%;
      width: 80%;
      height: 250px;
      background: #F2E8C7;
      box-shadow: inset 2px 2px 50px 10px #E6A045;
      border-radius: 10px;
      .title {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 30px;
        img {
          width: 80px;
        }
      }
      .text {
        width: 80%;
        font-size: 20px;
        margin-left: 40px;
        margin-top: 30px;
        line-height: 30px;
      }
    }
    .btn {
      position: fixed;
      left: 10%;
      top: 70%;
      width: 80%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      img {
        width: 130px;
      }
    }
  }
  // 补签成功
  .buqian1 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    z-index: 2;
    .box {
      position: fixed;
      left: 10%;
      top: 30%;
      width: 80%;
      height: 250px;
      background: #F2E8C7;
      box-shadow: inset 2px 2px 50px 10px #E6A045;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 130px;
      }
      .text {
        font-size: 20px;
        color: #5C5C5C;
        margin-top: 20px;
      }
    }
    .btn {
      position: fixed;
      left: 10%;
      top: 70%;
      width: 80%;
      height: 40px;
      display: flex;
      justify-content: center;
      img {
        width: 130px;
      }
    }
  }
}
.success {
  background: #EAA24D!important;
  color: white!important;
}
.fail {
  background: #BFBFBF!important;
  color: white!important;
  border-color: #BFBFBF!important;
}
</style>